<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">主页</el-breadcrumb-item>
      <el-breadcrumb-item>产品营销</el-breadcrumb-item>
      <el-breadcrumb-item>活动设置</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <el-row :gutter="30">
        <el-form
          :model="queryinfo"
          ref="queryinfoRef"
          :inline="true"
          style="text-align: left; margin-left: 40px"
        >
          <el-form-item prop="name">
            <div style="display: inline-block" class="divSpan">产品名称：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.name"
                :options="NameOptions"
                :optionKey="NameOptionKeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 200px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="actionStatus">
            <div style="display: inline-block" class="divSpan">活动状态：</div>
            <div style="display: inline-block">
              <ELselect
                v-model="queryinfo.actionStatus"
                :options="actionOptions"
                :optionKey="NameOptionKeys"
                Splaceholder="请选择"
                @clear="getList"
                style="width: 140px"
              ></ELselect>
            </div>
          </el-form-item>

          <el-form-item prop="payTime">
            <div style="display: inline-block" class="divSpan">
              活动开始日期：
            </div>
            <div style="display: inline-block">
              <el-date-picker
                type="datetimerange"
                v-model="queryinfo.payTime"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="截止日期"
                @change="dateSerach"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
              ></el-date-picker>
            </div>
          </el-form-item>

          <el-button type="primary" icon="el-icon-search" @click="seachChange"
            >查询</el-button
          >
          <el-button type="info" icon="el-icon-refresh" @click="clean"
            >重置</el-button
          >
        </el-form>
      </el-row>
      <el-row :gutter="30" style="text-align: right; margin-right: 40px">
        <el-button type="primary" @click="addAction">新建优惠活动</el-button>
      </el-row>

      <el-row :gutter="30" style="display: flex">
        <el-table
          :data="ActionList"
          :header-cell-style="{
            textAlign: 'center',
            background: '#FAFAFA',
            color: 'rgba(0, 0, 0, 0.85)',
            fontWeight: '500',
          }"
          tooltip-effect="dark"
          :cell-style="{
            textAlign: 'center',
          }"
        >
          <el-table-column type="index" min-width="50px"></el-table-column>
          <el-table-column
            prop="productName"
            label="产品名称"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="activityName"
            label="优惠活动名称"
            min-width="100px"
          ></el-table-column>

          <el-table-column
            prop="createTime"
            label="活动创建日期"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="startTime"
            label="活动开始日期"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="endTime"
            label="活动结束日期"
            min-width="150px"
          ></el-table-column>
          <el-table-column
            prop="total"
            label="套餐可售总数"
            min-width="100px"
          ></el-table-column>
          <el-table-column
            prop="num"
            label="已售数量"
            min-width="100px"
          ></el-table-column>
          <el-table-column label="活动状态" min-width="100px">
            <template slot-scope="scope">
              <el-tag
                :type="
                  scope.row.status == 0
                    ? 'primary'
                    : scope.row.status == 1
                    ? 'warning'
                    : scope.row.status == 2
                    ? 'success'
                    : 'danger'
                "
                >{{
                  scope.row.status == 0
                    ? "未启用"
                    : scope.row.status == 1
                    ? "待生效"
                    : scope.row.status == 2
                    ? "生效中"
                    : "已结束"
                }}</el-tag
              >
            </template>
          </el-table-column>

          <el-table-column label="操作" min-width="200px" fixed="right">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="details(scope.row)"
                class="buttons"
                >活动详情</el-button
              >

              <el-button
                size="mini"
                :type="
                  scope.row.status == 0
                    ? 'success'
                    : scope.row.status == 1
                    ? 'danger'
                    : 'warning'
                "
                class="buttons"
                @click="operatorStatus(scope.row)"
                v-show="scope.row.status != 3"
                >{{
                  scope.row.status == 0
                    ? "启用"
                    : scope.row.status == 1
                    ? "禁用"
                    : "结束"
                }}</el-button
              >

              <el-button
                size="mini"
                type="danger"
                @click="deletes(scope.row)"
                class="buttons"
                v-show="scope.row.status <= 1"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryinfo.pageNum"
        :page-size="queryinfo.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <el-dialog
      :close-on-click-modal="false"
      title="新建优惠活动"
      :visible.sync="createDialog"
      width="40%"
      @close="createDialogClosed"
    >
      <el-form
        :model="createForm"
        :rules="createFormRules"
        ref="createFormRef"
        label-width="100px"
        v-loading="loading"
        element-loading-text="图片正在上传中，请稍等"
      >
        <el-form-item label="活动名称：" prop="name">
          <el-input
            v-model.trim="createForm.name"
            placeholder="请输入"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动产品：" prop="product">
          <ELselect
            v-model="createForm.product"
            :options="NameOptions"
            :optionKey="NameOptionKeys"
            Splaceholder="请选择"
          ></ELselect>
        </el-form-item>
        <!-- <el-form-item label="活动链接：" prop="productUrl">
          <el-input
            v-model.trim="createForm.productUrl"
            placeholder="请输入"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="起止时间：" prop="payTime">
          <el-date-picker
            type="datetimerange"
            v-model="createForm.payTime"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="截止日期"
            @change="dateSerach"
            @input="dateSerach"
            value-format="yyyy-MM-dd HH:mm:ss"
            class="styles"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </el-form-item>

        <el-form-item
          label="活动图片："
          prop="logoImage.icon"
          :rules="createFormRules.Icons"
        >
          <UploadOneIcon
            :iconInfo="createForm.logoImage"
            tipss=" 用于移动端的展示，建议上传1080*1920的图片"
          ></UploadOneIcon>
        </el-form-item>

        <el-form-item
          label="重复购买："
          prop="repeatBuy"
          style="text-align: left"
        >
          <el-radio-group v-model="createForm.repeatBuy">
            <el-radio
              v-for="item in radioList"
              :key="item.menuIndex"
              :label="item.menuIndex"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item label="启用状态：" prop="status" style="text-align: left">
          <el-radio-group v-model="createForm.status">
            <el-radio
              v-for="item in radioList1"
              :key="item.menuIndex"
              :label="item.menuIndex"
              >{{ item.name }}</el-radio
            >
          </el-radio-group>
        </el-form-item>

        <el-form-item
          label="活动内容："
          prop="contents"
          style="text-align: left"
        >
          <el-button icon="el-icon-plus" @click="addContent">添加</el-button>
        </el-form-item>
        <el-row style="display: flex" v-if="showTable">
          <el-table
            :data="ActionContentList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            tooltip-effect="dark"
            :cell-style="{
              textAlign: 'center',
            }"
          >
            <el-table-column label="套餐类型" min-width="100px">
              <template slot-scope="scope">
                <ELselect
                  v-model="scope.row.packageKindId"
                  :options="mealOptions"
                  :optionKey="mealOptionKeys"
                  Splaceholder="请选择"
                  :Sclearable="false"
                  @change="changeType(scope.$index, scope.row)"
                ></ELselect>
              </template>
            </el-table-column>
            <el-table-column label="VIP会员时长(天)" min-width="120px">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.vipPeriod"
                  :precision="0"
                  :controls="false"
                  class="styles"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="官网原价(元)" min-width="100px">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.officialPrice"
                  :precision="2"
                  :controls="false"
                  class="styles"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="优惠价(元)" min-width="100px">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.finalPrice"
                  :precision="2"
                  :controls="false"
                  class="styles"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column label="可售总数" min-width="100px">
              <template slot-scope="scope">
                <el-input-number
                  v-model="scope.row.saleTotal"
                  :precision="0"
                  :controls="false"
                  class="styles"
                ></el-input-number>
              </template>
            </el-table-column>
            <el-table-column min-width="50px">
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="mini"
                  @click="deleteRow(scope.$index)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="createDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmAdd">确定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      title="活动详情"
      :visible.sync="actionDetailDialog"
      width="40%"
    >
      <el-form :model="detailForm" ref="createFormRef" label-width="100px">
        <el-form-item label="活动名称：">
          <el-input v-model.trim="detailForm.activityName" disabled></el-input>
        </el-form-item>
        <el-form-item label="产品名称：">
          <el-input v-model.trim="detailForm.productName" disabled></el-input>
        </el-form-item>
        <el-form-item label="活动链接：">
          <el-input v-model.trim="detailForm.url" disabled></el-input>
        </el-form-item>
        <el-form-item label="起止时间：">
          <el-input v-model.trim="detailForm.Times" disabled></el-input>
        </el-form-item>

        <el-form-item
          label="活动图片："
          prop="logoImage.icon"
          style="text-align: left"
          v-show="editFlag"
        >
          <img
            :src="detailForm.logoImage.icon"
            style="width: 144px; height: 144px"
          />
        </el-form-item>

        <el-form-item
          label="活动图片："
          prop="logoImage.icon"
          v-show="!editFlag"
        >
          <UploadOneIcon :iconInfo="detailForm.logoImage"></UploadOneIcon>
        </el-form-item>

        <el-form-item label="重复购买：">
          <el-input v-model.trim="detailForm.repeatBuy" disabled> </el-input>
        </el-form-item>

        <el-row style="display: flex">
          <el-table
            :data="detailForm.toolPackageList"
            :header-cell-style="{
              textAlign: 'center',
              background: '#FAFAFA',
              color: 'rgba(0, 0, 0, 0.85)',
              fontWeight: '500',
            }"
            tooltip-effect="dark"
            :cell-style="{
              textAlign: 'center',
            }"
          >
            <el-table-column
              label="套餐类型"
              min-width="100px"
              prop="packageKindName"
            >
            </el-table-column>
            <el-table-column
              prop="vipPeriod"
              label="VIP会员时长(天)"
              min-width="120px"
            ></el-table-column>
            <el-table-column
              prop="officialPrice"
              label="官网原价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column
              prop="finalPrice"
              label="优惠价(元)"
              min-width="100px"
            ></el-table-column>
            <el-table-column label="可售总数" min-width="100px">
              <template slot-scope="scope">
                {{ scope.row.saleTotal === 0 ? "不限" : scope.row.saleTotal }}
              </template>
            </el-table-column>
            <el-table-column
              prop="soldNum"
              label="已售数量"
              min-width="100px"
            ></el-table-column>
          </el-table>
        </el-row>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="actionDetailDialog = false">取消</el-button>
        <el-button type="primary" @click="confirmEdit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import ELselect from '@/components/myself/el_select'
import { deleteDialog } from '@/libs/confirmDialog'
import { getActivityList, enableActivity, deleteActivity, createActivity, getMarketingById } from '@/api/ActionSale.js'
import { ALLtoolList, getToolPackageKindList } from '@/api/toolmanager/toolVersion.js'
import { getFormatTime1 } from '@/components/myself/dateTime.js'
import { validatorInput } from '@/components/myself/speciaChaFilt.js'
import UploadOneIcon from '@/components/myself/UploadCommon/UploadOne.vue'

export default {
  components: { ELselect, UploadOneIcon },
  data () {
    return {
      queryinfo: {
        name: '',
        actionStatus: '',
        payTime: '',
        pageNum: 1,
        pageSize: 20,
      },
      total: 0,
      ActionList: [],

      NameOptions: [],
      NameOptionKeys: {
        value: 'id',
        label: 'name',
        label2: ''
      },
      actionOptions: [
        {
          id: '',
          name: '全部'
        },
        {
          id: 0,
          name: '未启用'
        },
        {
          id: 1,
          name: '待生效'
        },
        {
          id: 2,
          name: '生效中'
        },
        {
          id: 3,
          name: '已结束'
        }],

      createDialog: false,

      createForm: {
        name: '',
        product: '',
        productUrl: window.location.origin.includes('cloud') ? window.location.origin.replace('cloud', 'market') + '/#/LowCodeAction' : window.location.origin.replace('9191', '8181') + '/#/LowCodeAction',
        logoImage: {
          icon: '',
          iconSize: '',
          IconObject: {},
          acceptImage: ".png,.jpg,.gif,.bmp",
          type: 'picture'
        },
        repeatBuy: 0,
        status: 0,
        contents: [],
      },
      createFormRules: {
        product: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        productUrl: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        name: [
          { required: true, message: '名称不能为空', trigger: 'blur' },
          { validator: validatorInput, trigger: 'blur' },
          { min: 1, max: 50, message: '长度需在1-50个字符间', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        repeatBuy: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        contents: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        Icons: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
        payTime: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ],
      },
      radioList: [
        { menuIndex: 0, name: '不允许' },
        { menuIndex: 1, name: '允许' },
      ],
      radioList1: [
        { menuIndex: 0, name: '暂不启用' },
        { menuIndex: 1, name: '立即启用' },
      ],
      ActionContentList: [],
      showTable: false,
      mealOptions: [],
      mealOptionKeys: {
        value: 'packageKindId',
        label: 'packageKindName',
        label2: ''
      },
      actionDetailDialog: false,
      uploadSuccess: false,
      loading: false,
      detailForm: {
        logoImage: {
          icon: '',
          iconSize: '',
          IconObject: {},
          acceptImage: ".png,.jpg,.gif,.bmp",
          type: 'picture'
        },
      },
      kindID: '',
      editFlag: false


    }
  },
  mounted () {
    this.getList()
    this.getProduct()
  },
  methods: {
    async getProduct () {
      const res = await ALLtoolList()
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.NameOptions = res.data.data
    },

    async getList () {
      let time1 = this.queryinfo.payTime ? this.queryinfo.payTime[0] : ''
      let time2 = this.queryinfo.payTime ? this.queryinfo.payTime[1] : ''
      let quiese = { itemId: this.queryinfo.name, status: this.queryinfo.actionStatus, startTime: time1, endTime: time2 }
      const res = await getActivityList(this.queryinfo.pageNum, this.queryinfo.pageSize, quiese)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.ActionList = res.data.data.list
      this.total = res.data.data.total
    },
    clean () {
      this.$refs.queryinfoRef.resetFields()
      this.getList()
    },
    dateSerach () {
      this.getList()
    },
    seachChange () {
      this.queryinfo.pageNum = 1
      this.getList()
    },
    handleSizeChange (newSize) {
      this.queryinfo.pageSize = newSize
      this.getList()
    },
    handleCurrentChange (newPage) {
      this.queryinfo.pageNum = newPage
      this.getList()
    },

    async details (para) {
      this.kindID = para.id
      this.editFlag = false
      const res = await getMarketingById(para.id)
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      let resultObj = res.data.data
      this.detailForm.activityName = resultObj.activityName
      this.detailForm.productName = resultObj.productName
      this.detailForm.url = resultObj.url
      this.detailForm.toolPackageList = resultObj.toolPackageList
      this.detailForm.Times = resultObj.startTime + ' - ' + resultObj.endTime
      this.detailForm.logoImage.icon = resultObj.pictureUrl
      this.detailForm.logoImage.iconSize = ''
      this.detailForm.repeatBuy = Number(resultObj.repeatBuy) == 0
        ? this.radioList[0].name
        : this.radioList[1].name

      if (para.status == 1) {
        let result = getFormatTime1(this.detailForm.startTime, this.detailForm.dateNow)
        if (result.stayDay <= 0 && result.stayHour <= 24) {
          this.editFlag = true
        }
      }

      this.actionDetailDialog = true
    },
    async confirmEdit () {
      if (this.detailForm.logoImage.iconSize) {
        let quiese = { id: this.kindID, resourceFile: this.detailForm.logoImage.IconObject }
        const res = await createActivity(quiese)
        if (res.status !== 200) return this.$message.error('操作失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('操作成功')
        this.actionDetailDialog = false
      } else {
        return this.$message.error('请修改活动图片')
      }
    },

    async operatorStatus (para) {
      let strs = ''
      if (para.status == 0) {
        strs = '活动启用后到达生效时间将会自动生效，您确认要启用当前活动吗?'
      } else if (para.status == 1) {
        strs = '您确认要禁用当前活动吗?'
      } else {
        strs = '您确认要提前结束当前活动吗?'
      }
      const Result = await deleteDialog(strs, this)
      if (Result === 'confirm') {
        const res = await enableActivity(para.id)
        if (res.status !== 200) return this.$message.error('获取失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('操作成功')
        this.getList()
      }
    },

    async deletes (para) {
      const Result = await deleteDialog('您确认要删除当前活动吗?', this)
      if (Result === 'confirm') {
        const res = await deleteActivity(para.id)
        if (res.status !== 200) return this.$message.error('获取失败')
        if (res.data.status !== 200) return this.$message.error(res.data.message)
        this.$message.success('操作成功')
        this.getList()
      }
    },

    async getTooltype () {
      const res = await getToolPackageKindList()
      if (res.status !== 200) return this.$message.error('获取失败')
      if (res.data.status !== 200) return this.$message.error(res.data.message)
      this.mealOptions = res.data.data
    },
    addAction () {
      this.showTable = false
      this.ActionContentList = []
      this.getTooltype()
      this.createDialog = true
    },
    createDialogClosed () {
      this.$refs.createFormRef.resetFields()
    },

    addContent () {
      this.showTable = true
      this.ActionContentList.push({ packageKindId: '', vipPeriod: 0, officialPrice: 0, finalPrice: 0, saleTotal: 0 })
    },
    deleteRow (index) {
      this.ActionContentList.splice(index, 1)
      this.createForm.contents.splice(index, 1)

      if (this.ActionContentList.length <= 0) {
        this.showTable = false
      }
    },

    changeType (para, para1) {
      this.ActionContentList[para].packageKindId = para1.packageKindId
      this.createForm.contents[para] = para1.packageKindId
    },

    confirmAdd () {
      this.$refs.createFormRef.validate(async valid => {
        if (valid) {
          let quiese = { activityName: this.createForm.name, url: this.createForm.productUrl, itemId: this.createForm.product, startTime: this.createForm.payTime[0], endTime: this.createForm.payTime[1], repeating: this.createForm.repeatBuy, status: this.createForm.status, resourceFile: this.createForm.logoImage.IconObject, toolPackageList: this.ActionContentList }
          const res = await createActivity(quiese)
          if (res.status !== 200) return this.$message.error('操作失败')
          if (res.data.status !== 200) return this.$message.error(res.data.message)
          this.$message.success('操作成功')
          this.createDialog = false
          this.getList()
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.divSpan {
  font-size: 16px;
}

/deep/ .el-form-item__content {
  margin-right: 30px;
}

.styles {
  width: 100%;
}
.buttons {
  margin-top: 5px;
}
</style>